import React, { useEffect, useState, useRef } from "react";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import Container from "react-bootstrap/Container";
import LoginModal from "../Modals/login_modal";
import CartModal from "../Modals/cart_modal";
import Modal from "react-bootstrap/Modal";
import MenuModal from "../Modals/menu_modal";
import { ApiService } from "../../Components/Services/apiservices";
import HeaderMenu from "../Elements/header_menu";
import sessionCartData from "../../Components/Elements/cart_session_data";
import SpinnerLoader from "../Elements/spinner_loader";
import multiCurrency from "../../Components/Elements/multi_currrency";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-loading-skeleton/dist/skeleton.css'

function Header({ cartCount = 0, state = "" }) {
  const didMountRef = useRef(true);
  const multiCurrencyData = multiCurrency();
  const dataArray = sessionCartData();
  const parsedCartSession = dataArray[1];
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleChildData = (status) => {  setShow(status)};
  const [settingImagePath, setSettingImagePath] = useState("");
  const [spinnerLoading, setSpinnerLoading] = useState(true);
  const [showCart, setShowCart] = useState(false);
  const [cartcounttest, setcartcounttest] = useState(cartCount);
  const handleShowCart = () => setShowCart(true);
  const handleChildCartData = (status) => {  setShowCart(status) };
  const handleClose = () => {setShowCart(false)  };
  const [showMenuCart, setShowMenuCart] = useState(false);
  const handleShowMenuCart = () => setShowMenuCart(true);
  const handleChildMenuCartData = (status) => { setShowMenuCart(status)  };
  const handleMenuClose = () => {setShowMenuCart(false)};
  const [setSession, SetSession] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [headerdata, setHeaderData] = useState({});
  const [settingData, setSettingData] = useState({});
  const [selectedCurrency, setSelectedCurrency] = useState("INR");

  useEffect(() => {
    if (didMountRef.current) {
      handleChangeCurrency();
      getheaderdata();
      getSettingsData();
      SetSession(localStorage.getItem("USER_TOKEN"));
    }
    didMountRef.current = false;
    if(typeof window!="undefined"){
      const handleScroll=()=>{
        const headerTop=document.getElementById("header_top")
        if(headerTop && window.scrollY>200){
          headerTop.classList.add("fixed-head")
          document.body.style.paddingTop = `${headerTop.offsetHeight}px`;
        }else{
          headerTop?.classList.remove("fixed-head");
          document.body.style.paddingTop = "0";
        }
      };
      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }
  }, []);
  const NextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        Next
      </div>
    );
  };
  const PrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        Prev
      </div>
    );
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchTerm.length >= 2) {fetchSearchResults()};
    }, 300); // Debounce time (adjust as needed)

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const getheaderdata = () => {
    ApiService.fetchData("header-data").then((res) => {
      if (res.status === "success") { setHeaderData(res.headerdata)};
    });
  };


  const getSettingsData = () => {
    ApiService.fetchData("settings").then((res) => {
      if (res.status === "success") {
        setSettingData(res.sitesettings);
        setSettingImagePath(res.setting_image_path)

      }
    });
  };

  const handleInputChange = (event) => setSearchTerm(event.target.value);
  const fetchSearchResults = () => {
    ApiService.postData("getsearchdata", { query: searchTerm,}).then((res) => {
      if (res.status === "success") {
        setSearchResults(res.data);
      } else {
      }
    });
  };
  const wrapperRef = useRef(null);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setSearchResults([]);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside) }, []);
    
  const handleChangeCurrency = () => {
    const MultiCurrencySession = localStorage.getItem("MULTI_CURRENCY");
    const parsedMultiCurrencySession = MultiCurrencySession  ? JSON.parse(MultiCurrencySession)  : {};
    const selectedValue = parsedMultiCurrencySession ? parsedMultiCurrencySession.cr_currency_select : selectedCurrency;
    
    setSpinnerLoading(true);
    ApiService.postData("currency-rates", {selectedValue}).then((res) => {
      if (res.status === "success") {
        setSelectedCurrency(selectedValue);
        localStorage.setItem( "MULTI_CURRENCY",JSON.stringify(res.rowCurrencyRates));
        setSpinnerLoading(false);
      } else {
        setSpinnerLoading(false);
      }
    });
  };
  const handleBlur = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setSearchTerm("");
    }
  };

  const handleChange = (event) => {
    const MultiCurrencySession = localStorage.getItem("MULTI_CURRENCY");
    const parsedMultiCurrencySession = MultiCurrencySession ? JSON.parse(MultiCurrencySession)  : {};
    const selectedValue = event ? event.target.value : (parsedMultiCurrencySession ? parsedMultiCurrencySession.cr_currency_select : selectedCurrency);
    const dataString = { selectedValue };
    setSpinnerLoading(true);
    ApiService.postData("currency-rates", dataString).then((res) => {
      if (res.status === "success") {
        setSelectedCurrency(selectedValue);
        localStorage.setItem( "MULTI_CURRENCY",JSON.stringify(res.rowCurrencyRates));
        setSpinnerLoading(false);
        window.location.reload();
      } else {
        setSpinnerLoading(false);
      }
    });
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1, // Number of slides to show at a time
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0
        }
      }
    ]
  };
 

  return (
    <>
      {spinnerLoading && <SpinnerLoader />}
      <BrowserView>
      <div className="tobar">
      <Slider {...settings}>
        <div className="marquee-content">
          
          <span className="shipping-line"><img src="/img/gift.png" width={17} style= {{marginRight:"7px",marginBottom: "4px"}}/>
          FREE Gift on all PREPAID Orders
          </span>
        </div>
        <div className="marquee-content">
          
        <span className="shipping-line"><img src="/img/shipping.png" width={22}  style= {{marginRight:"7px"}}/>
        FREE Shipping on EVERY Order
        </span>
        </div>
      
       
      </Slider>
    </div>
        {/* {headerdata?.header_top != null && headerdata.header_top !== "" && (
          <div className="top-header">
            <Container>
              <div className="row justify-content-center align-items-center">
                <div className="col-lg-6">
                  <marquee>{headerdata.header_top}</marquee>
                </div>
              </div>
            </Container>
          </div>
        )} */}
        <header className="header">
          <div
            className={
              `fixed header header-middle ${state}`
            }
            id="header_top"
          >
            <Container> 
              <div className="header-left">
                <a href="/" className="logo">
                <img
                    src={settingData.logo != null ? settingImagePath + settingData.logo : "/img/logo.png"} alt="shoppandit99"
                    width={85}
                    height={63}
                  />
                  {/* <img src="/img/logo.png" alt="logo" width="85" height="63" /> */}
                </a>
                <div className="header-search" onBlur={handleBlur}>
                  <form action="#" className="input-wrapper">
                    <input
                      type="text"
                      className="form-control search-bar"
                      name="search"
                      autoComplete="off"
                      placeholder="Search..."
                      required=""
                      onChange={handleInputChange}
                      value={searchTerm}
                    />
                    {searchResults &&
                    searchResults.length > 0 &&
                    searchTerm.trim() !== "" ? (
                      <div className="header-search-list" id="search_input">
                        <ul>
                          {searchResults.map((value, index) => (
                            <a href={value.redirect}>
                              <li key={index}>{value.name}</li>
                            </a>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      ""
                    )}
                    <button  className="btn btn-search" type="submit" title="submit-button">
                      <i className="d-icon-search"></i>
                    </button>
                  </form>
                </div>
              </div>
              <div className="header-right justify-content-end">
                {setSession ? (
                  <>
                    <a
                      className="login-link" href="/account/account-overview" title="login">
                      <i className="d-icon-user"></i>
                    </a>
                    <a
                      className="wishlist" href="/account/wishlist" title="wishlist">
                     <i className="d-icon-heart"></i>
                    </a>
                  </>
                ) : (
                  <>
                    <a
                      className="login-link" href="javascript:void(0)" title="login" onClick={handleShow}>
                      <i className="d-icon-user"></i>
                    </a>
                    <a
                      className="wishlist" href="javascript:void(0)"title="wishlist" onClick={handleShow}>
                      <i className="d-icon-heart"></i>
                    </a>
                  </>
                )}
                <a className="cart-toggle" href="javascript:void(0)"  title="cart"onClick={handleShowCart}>
                  <i className="d-icon-bag"></i>
                  {showCart ? (
                    <span className="cart-count">{cartCount}</span>
                  ) : (<span className="cart-count">{parsedCartSession.length}</span>)}
                    </a>
                 </div>
            </Container>
          </div>
          <div className="header-bottom">
            <Container>
              <HeaderMenu />
            </Container>
          </div>
        </header>
        
      </BrowserView>
      <MobileView>
      <div className="tobar">
      <Slider {...settings}>
        <div className="marquee-content">
          
          <span className="shipping-line"><img src="/img/gift.png" width={17}  style= {{marginRight:"7px" ,marginBottom: "4px"}}/>FREE Gift on all PREPAID Orders  </span>
        </div>
        <div className="marquee-content">
          
          <span className="shipping-line"><img src="/img/shipping.png" width={22}  style= {{marginRight:"7px"}}/>FREE Shipping on EVERY Order</span>
        </div>
      
       
      </Slider>
    </div>
        {/* {headerdata?.header_top != null && headerdata.header_top !== "" ? (
          <div className="top-header">
            <Container>
              <div className="row justify-content-center align-items-center">
                <div className="col-lg-12 ">
                  <marquee>{headerdata.header_top}</marquee>
                </div>
              </div>
            </Container>
          </div>
        ) : null} */}
        <header className="mheader d-flex">
          <div className="mheader-left">
            <a  href="javascript:void(0)"className="sidenav-trigger" onClick={handleShowMenuCart}>
              <i className="d-icon-bars2"></i>
            </a>
            <a href="/" className="mlogo"><img src="/img/logo.png" alt="logo" width="65" height="47" />
            </a>
          </div>

          <div className="mheader-right">
            <a className="search-link" href="/search" title="search">
              <i className="d-icon-search"></i>
            </a>
            {setSession ? (
              <>
                <a className="login-link" href="/account/account-overview" title="login" >
                  <i className="d-icon-user"></i>
                </a>
              </>
            ) : (
              <>
                <a className="login-link" href="javascript:void(0)" title="login" onClick={handleShow}>
                  <i className="d-icon-user"></i>
                </a>
              </>
            )}
            <a className="cart-toggle" href="javascript:void(0)" title="cart"onClick={handleShowCart}>
              <i className="d-icon-bag"></i>
              {showCart ? (<span className="cart-count">{cartCount}</span>   ) : (
                <span className="cart-count">{parsedCartSession.length}</span>
              )}
            </a>
          </div>
        </header>
      </MobileView>
      {show && <LoginModal showmodal={show} onChildData={handleChildData} />}
      <Modal show={showCart} onHide={handleClose} className="right cart-modal">
        {showCart && (
          <CartModal
            showcartmodal={showCart}
            onChildCartData={handleChildCartData}
          />
        )}
      </Modal>

      <Modal
        show={showMenuCart}
        onHide={handleMenuClose}
        className="left menu-modal"
      >
        {showMenuCart && (
          <MenuModal
            showMenucartmodal={showMenuCart}
            onChildCartData={handleChildMenuCartData}
          />
        )}
      </Modal>
    </>
  );
}
export default Header;
