import React, { useEffect, useRef, useState } from "react";
import constant from "../Services/constant";
import { ApiService } from "../Services/apiservices";
import Skeleton from "react-loading-skeleton";
import { BrowserView, MobileView } from "react-device-detect";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay} from "swiper/modules";
function GridBannerFirst({ indexLoop, onParentData, dataObj }) {
  const didMountRef = useRef(true);
  //const [resAfterTopBannerData, setResAfterTopBannerData] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const [loading, setLoading] = useState();
  useEffect(() => {
    if (didMountRef.current) {
      //getBannerData();
    }
    didMountRef.current = false;
  }, []);
  /* const getBannerData = () => {
    setLoading(true);
    ApiService.fetchData("after-top-banner-list").then((res) => {
      if (res.status == "success") {
        setResAfterTopBannerData(res.resAfterTopBannerData);
        setImageUrl(res.slider_image_path);
        setLoading(false);
      }
    });
  };  */

  return (
    <>
      <BrowserView>
        {dataObj.spinnerLoading == true ? ( 
          <a className="addbox overlay-zoom mb-3">
            <Skeleton variant="text" width={390} height={220} />
          </a> 
        ) : (
         <>
            {dataObj.home_image !== null ? (
              <a
                href={dataObj.home_image_link !== '' ? dataObj.home_image_link : 'javascript:void(0)'}
                className="addbox overlay-zoom mb-3"
              >
                <img src={dataObj.home_image} alt='Banner' />
              </a>
            ) : dataObj.home_image_url !== null ? (
              <a
                href={dataObj.home_image_link !== '' ? dataObj.home_image_link : 'javascript:void(0)'}
                className="addbox overlay-zoom mb-3"
              >
                <img src={dataObj.home_image_url} alt='Banner' />
              </a>
            ) : null}
        </>
        )}
      </BrowserView>
      <MobileView>
        {dataObj.spinnerLoading == true ? ( 
          <a className="addbox overlay-zoom mb-3">
            <Skeleton variant="text" width={390} height={220} />
          </a>
        ) : (
         <>
                {dataObj.home_image !== null ? (
              <a
                href={dataObj.home_image_link !== '' ? dataObj.home_image_link : 'javascript:void(0)'}
                className="addbox overlay-zoom mb-3"
              >
                <img src={dataObj.home_image} alt='Banner' />
              </a>
            ) : dataObj.home_image_url !== null ? (
              <a
                href={dataObj.home_image_link !== '' ? dataObj.home_image_link : 'javascript:void(0)'}
                className="addbox overlay-zoom mb-3"
              >
                <img src={dataObj.home_image_url} alt='Banner' />
              </a>
            ) : null}
        </>
        )}
      </MobileView>
    </>
  );
}
export default GridBannerFirst;
